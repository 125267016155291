@import url(../../App.css);
.share-ol-container{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.share-ol-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 60%;
    display: flex;
    min-height: 465px;
}
.share-ol-left{
    width: 30%;
    padding: 12px;
    border-right: 1px solid var(--accent4);

}
.share-ol-right{
    width: 70%;
    padding: .5rem 1rem;
    background-color: var(--accent2);
    border-radius: 8px;
}
.share-ol-close{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}
.share-ol-listitem{
    padding: .5rem .5rem;
    border-radius: .4rem;
    font-size: .9rem;
    cursor: pointer;
    margin-bottom: .3rem;
    color: var(--accent3);
    display: flex;
    align-items: center;
}
.share-ol-listitem > span > svg{
    margin-right: .5rem;
    position: relative;
    top: 2px;
}
.share-ol-listitem:hover{
    background-color: var(--accent2);
}
.listitem-active{
    background-color: var(--accent2);
    color: var(--linkBlue);
}
.share-ol-heading{
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--lightBlack);
}
.share-ol-subheading{
    margin-top: .5rem;
    font-size: .95rem;
}
.im-content{
    margin: 1rem 0rem;
}
.im-share-input{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: .5rem;
    position: relative;
}

.invite-input{
    width: 86%;
    padding: .7rem;
    border: 1px solid var(--accent4);
    border-radius: .3rem;
}
/* .css-b62m3t-container{
    width: 86%;
    border-radius: .3rem;
}
.css-b62m3t-container:focus-within ~ .invite-btn{
    border: 1px solid var(--linkBlue);
}
.css-3w2yfm-ValueContainer{
    flex-wrap: nowrap !important;
    -webkit-flex-wrap: nowrap !important;
    -webkit-box-flex-wrap: nowrap !important;
} */
.invite-btn{
    border: 1px solid var(--accent4);
    padding: .5rem .9rem;
    cursor: pointer;
    border-radius: .3rem;
    color: var(--accent3);
    transition: all .3s linear;
}
.invite-btn:hover{
    border: 1px solid var(--linkBlue);
    background-color: var(--linkBlue);
    color: var(--white);
}
.invite-input:focus+.invite-btn{
    border: 1px solid var(--linkBlue);
}
.im-member-list{
    background-color: var(--white);
    margin-top: 1rem;
    border: 1px solid var(--accent4);
    border-radius: .3rem;
}
.im-member-list > div{
    padding: 15px 20px;
}
.im-member-list > div:first-child{
    border-bottom: 1px solid var(--accent4);
    font-size: 1.1rem;
    font-weight: 500;
}
.im-member-list > div:last-child{
    max-height: 215px;
    overflow: scroll;
}
.im-listitem{
    display: flex;
    justify-content: space-between;
    margin-bottom: .8rem;
}
.im-image{
    height: 36px;
    width: 36px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid var(--accent4);
}
.im-image > img{
    height: 36px;
    width: 36px;
}
.listitem-left{
    display: flex;
    align-items: center;
}
.im-details{
    margin-left: .5rem;
}
.im-name{
    font-weight: 400;
}
.im-email{
    font-size: .7rem;
    color: var(--accent3);
}
.invite-link{
    padding: 7px;
    border: 1px solid var(--accent4);
    border-radius: .4rem;
    display: flex;
    justify-content: space-between;
}
.invite-link > input{
    width: 80%;
    padding: 8px;
    border: none;
    outline: none;
}
.invite-link > button{
    padding: 0px 8px;
    background-color: var(--linkBlue);
    border: none;
    border-radius: .4rem;
    color: var(--white);
    cursor: pointer;
}
.invite-link > button:hover{
    background-color: #0069bf;
}
.user-list{
    display: none;
    position: absolute;
    top: 100%;
    width: 86%;
    max-height: 200px;
    background-color: var(--white);
    border: 1px solid var(--accent4);
    border-radius: .4rem;
    z-index: 9;
    overflow-y: scroll;
    padding: .5rem;
}
.user-list-active{
    display: block;
}
.user-list-alert{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-style: italic;
    color: var(--accent3);
}
.user-item{
    padding: .3rem .5rem;
    border-radius: .4rem;
    cursor: pointer;
    font-size: .9rem;
    cursor: pointer;
}
.user-item-name{
    font-size: .8rem;
    color: var(--accent3);
}
.user-item:hover{
    background-color: var(--accent2);
}
.pw-subdomain-box{
    margin-top: 1rem;
}
.pw-subdomain-label{
    font-size: .8rem;
}
.pw-subdomain-input-box{
    display: flex;
    align-items: center;
}
.pw-subdomain-input{
    background-color: var(--accent4);
    padding: .6rem 0.5rem;
}
.pw-subdomain-input-box > input{
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
}
.pw-subdomain-input{
    border: 1px solid var(--accent4);
    border-left: none;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}
.pw-publish-btn{
    background-color: var(--linkBlue);
    width: fit-content;
    color: var(--white);
    padding: .5rem 1rem;
    margin-top: 1rem;
    border-radius: .3rem;
    cursor: pointer;
    border: none;
    font-size: 1rem;
}
.pw-publish-btn:hover{
    box-shadow: inset 0 0 12px 2px #1592f8;
}
.pw-subdomain-err{
    font-size: .75rem;
    font-style: italic;
    color: red;
    margin-top: .2rem;
}