.cdx-warning {
    position: relative;
  
  }
  .cdx-warning-no-before::before{
    content: none;
  }
  .cdx-warning-no-padding{
    padding-left: 0px;
  }

  @media all and (min-width: 736px) {
    .cdx-warning {
      padding-left: 36px;
    }
    .cdx-warning-no-padding{
      padding-left: 0px;
    }
  }
  
  .cdx-warning [contentEditable=true][data-placeholder]::before{
    position: absolute;
    content: attr(data-placeholder);
    color: #707684;
    font-weight: normal;
    opacity: 0;
  }
  
  .cdx-warning [contentEditable=true][data-placeholder]:empty::before {
    opacity: 1;
  }
  
  .cdx-warning [contentEditable=true][data-placeholder]:empty:focus::before {
    opacity: 0;
  }
  
  
  .cdx-warning::before {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='5' y='5' width='14' height='14' rx='4' stroke='black' stroke-width='2'/%3E%3Cline x1='12' y1='9' x2='12' y2='12' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M12 15.02V15.01' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    position: absolute;
    margin-top: 8px;
    left: 0;
  }

  .cdx-warning-no-before::before{
    content: none;
  }
  .cdx-warning-no-padding{
    padding-left: 0px;
  }
  
  @media all and (max-width: 735px) {
    .cdx-warning::before {
      display: none;
    }
    .cdx-warning-no-padding{
      padding-left: 0px;
    }
  }
  
  .cdx-warning__message {
    min-height: 85px;
  }
  
  .cdx-warning__title {
    margin-bottom: 6px;
  }