:root {
    /* Code Block styles */
  --color-code-bg: #252935;
  --color-code-main: #E1EBFE;
  --color-code-keyword: #ff6675;
  --color-code-class: #bf9dff;
  --color-code-variable: #69c6ff;
  --color-code-string: #81bcff;
  --color-code-params: #ffa259;
  --color-code-tag: #74e59d;
  --color-code-number: #ff6262;
  --color-code-comment: #6c7f93;
}

.ce-code__textarea {
    min-height: 200px;
    font-family: Menlo, Monaco, Consolas, Courier New, monospace;
    color: #41314e;
    line-height: 1.6em;
    font-size: 12px;
    background: #f8f7fa;
    border: 1px solid #f1f1f4;
    box-shadow: none;
    white-space: pre;
    word-wrap: normal;
    overflow-x: auto;
    resize: vertical;
}

code {
    background: rgba(250, 239, 240, 0.78) !important;
    color: #b44437 !important;
}

pre {
    width: 100%;
    overflow-x: auto;
    background: #252935;
    border-radius: 8px;
    color: #e1ebfe;
    font-family: Menlo,Monaco,Consolas,Courier New,monospace;
    /* font-family: var(--font-mono); */
    font-size: 13px;
    line-height: 1.5em;
    overflow-x: auto;
}

pre code {
    word-wrap: normal;
    background: transparent!important;
    box-sizing: border-box;
    color: #e1ebfe!important;
    color: var(--color-code-main)!important;
    display: inline-block!important;
    min-width: 100%;
    padding: 15px!important;
    white-space: pre;
}

.diff {
	 display: inline-block;
	 width: 100%;
}
 .diff--added {
	 color: #70b979;
	 background-color: #25f84d 21;
}
 .diff--added::before {
	 content: '+';
	 opacity: 0.8;
}
 .diff--removed {
	 color: #f1acaf;
	 background-color: #950000 69;
}
 .diff--removed::before {
	 content: '-';
	 opacity: 0.8;
}
 

 .hljs-params {
    color: var(--color-code-params) !important;
  }

  .hljs-number {
    color: var(--color-code-number) !important;
  }


  .hljs-title,
  .hljs-title.class_,
  .hljs-title.class_.inherited__,
  .hljs-title.function_  {
    color: var(--color-code-class) !important;
  }


  .hljs-name,
  .hljs-section,
  .hljs-selector-tag {
    color: var(--color-code-tag) !important;
  }

  .hljs-tag {
    color: var(--color-code-main) !important;
  }

  .hljs-attr,
  .hljs-selector-attr,
  .hljs-selector-class,
  .hljs-selector-id,
  .hljs-selector-pseudo,
  .hljs-title {
    color: var(--color-code-class) !important;
  }

  .hljs-attribute,
  .hljs-literal,
  .hljs-operator {
    color: var(--color-code-variable) !important;
  }

  .hljs-emphasis,
  .hljs-quote,
  .hljs-string,
  .hljs-strong,
  .hljs-template-variable,
  .hljs-variable {
    color: var(--color-code-string) !important;
  }

  .hljs-doctag,
  .hljs-keyword,
  .hljs-meta .hljs-keyword,
  .hljs-template-tag,
  .hljs-template-variable,
  .hljs-type {
    color: var(--color-code-keyword) !important;
  }

  .hljs-variable.language_ {
    color: var(--color-code-variable)  !important;
  }

  .hljs-code,
  .hljs-comment,
  .hljs-formula {
    color: var(--color-code-comment) !important;
  }

  .hljs-regexp {
    color: var(--color-code-tag) !important;
  }