@import url(../../App.css);
.rl-container{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1000;
}
.show-reloader{
    display: block;
}
.rl-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rl-outer{
    /* opacity: 0; */
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 12px solid #ddd;
    border-top-color: var(--linkBlue);
    animation: rotateBorder 1.5s linear infinite;
}


@keyframes rotateBorder {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}