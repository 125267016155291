@import url(../../App.css);
.topbar-container{
    width: 100%;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
}
.topbar-left{
    display: flex;
    align-items: center;
    margin-left: 30px;
}
.topbar-img{
    padding: 1.5px 4px;
    font-size: 20px;
    background-color: var(--accent2);
    border: 1px solid var(--accent1);
    border-radius: 3px;
    margin-right: 10px;
}
.topbar-filename{
    padding: 3px 10px;
    border-radius: 5px;
}
.topbar-filename > span{
    font-size: 26px;
    font-weight: 700;
    color: var(--accent3);
    outline: none;
}
.topbar-filename:hover{
    background-color: var(--accent2);
}
.topbar-right{
    display: flex;
    align-items: center;
}
.more-option{
    margin: 0 20px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.more-option:hover{
    background-color: var(--accent2);
    cursor: pointer;
}
a{
    text-decoration: none;
}
.share-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding:  5px 10px;
    border-radius: 4px;
    color: var(--lightBlack);
    cursor: pointer;
    text-decoration: none !important; 
}
.share-btn:hover{
    border: 1px solid var(--lightBlack);
}
.share-btn > svg{
    margin: 0px 3px;
}
.save-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding:  5px 10px;
    border-radius: 4px;
    color: var(--lightBlack);
    cursor: pointer;
    margin-left: 10px;
}
.save-btn:hover{
    background-color: #50C878;
    border: 1px solid #50C878;
    color: white;
}
.save-btn > svg{
    margin: 0px 5px;
}
.save-btn:hover > svg > path{
    stroke: white;
}
.topbar-menu{
    background-color: white;
    padding: 10px;
    position: absolute;
    right: -80%;
    top: 100%;
    border: 1px solid var(--accent4);
    border-radius: 5px;
    width: 220px;
    display: none;
}
.topbar-menu > p{
    padding: 6px 10px;
    margin: 5px 0px;
    font-size: var(--medium-para);
    display: flex;
    align-items: center;
}
.topbar-menu > p:hover{
    cursor: pointer;
    background-color: var(--accent2);
    border-radius: 4px;
}
.topbar-menu > p > span{
    margin-right: 10px;
    position: relative;
    top: 2px;
}
.topbar-menu-active{
    display: block;
}
.more-option:hover .topbar-menu{
    display: block;
}

.topbar-mob-hamburger{
    display: none;
}
/* Responsive CSS */
@media (max-width: 800px) and (min-width: 410px){
    .share-btn, .save-btn{
        display: none;
    }
    .topbar-filename{
        width: 270px;
        overflow: hidden;
    }
    .topbar-filename > span{
        font-size: var(--h6);
        white-space: nowrap;
    }
    .topbar-left{
        margin-left: 10px;
    }
    .topbar-mob-hamburger{
        display: block;
        margin-right: 10px;
        cursor: pointer;
        position: relative;
        top: 2px;
    }
    .more-option{
        margin: 0 10px;
    }
}
@media (max-width: 410px) and (min-width: 300px){
    .share-btn, .save-btn{
        display: none;
    }
    .topbar-filename{
        width: 230px;
        overflow: hidden;
    }
    .topbar-filename > span{
        font-size: var(--para);
        white-space: nowrap;
    }
    .topbar-left{
        margin-left: 10px;
    }
    .topbar-mob-hamburger{
        display: block;
        margin-right: 10px;
        cursor: pointer;
        position: relative;
        top: 2px;
    }
    .more-option{
        margin: 0 10px;
    }
}