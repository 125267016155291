@import url(../../App.css);
.single-menu{
    padding: 8px 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: var(--medium-para);
    cursor: pointer;
}
.is-group-page{
    margin-left: 10px;
}
.single-menu > a{
    text-decoration: none;
    color: #5B6975;
}
.single-menu-open{
    background-color: #F2F6FB;
}
.single-menu:hover{
    background-color: #eceff1;
}
