@import url(../../App.css);
.ip-list{
    margin: 2px 0px;
    padding: 3px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.ip-list-left{
    display: flex;
    align-items: center;
    color: var(--accent4);
}
.ip-list-left > p:nth-child(1){
    margin-right: 10px;
}
.ip-list:hover{
    background-color: var(--searchBlue);
}
.ip-list:hover .ip-list-right{
    visibility: visible;
}
.ip-list-right{
    visibility: hidden;
    position: relative;
    top: 2px;
}
.ip-list-active .ip-list-right{
    visibility: visible;
}
.ip-list-active{
    background-color: var(--searchBlue);
}
.add-list{
    padding: 6px 15px;
    display: flex;
    align-items: center;
    color: var(--accent4);
    cursor: pointer;
    font-size: var(--medium-para);
}
.add-list>p:nth-child(1){
    margin-right: 15px;
}
.add-list:hover{
    background-color: var(--searchBlue);
}