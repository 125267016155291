@import url(../../App.css);
.rsb-section{
    width: 15%;
    padding-top: 100px;
    height: 100vh;
    overflow-y: scroll;
}
.rsb-container{
    width: 100%;
}
.rsb-heading{
    font-size: var(--small-para);
    font-weight: 700;
    color: var(--accent3);
    padding-left: 20px;
    margin: 20px 0px;
}

@media (max-width: 800px) and (min-width: 300px) {
    .rsb-section{
        display: none;
    }
}