@import url(../../App.css);
a{
    text-decoration: none;
    color: var(--linkBlue);
}
a:hover{
    text-decoration: underline;
    color: var(--black);
}
.content-section{
    padding-top: 60px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
    width: 65%;
    height: 100vh;
    overflow-y: scroll;

}
.content-section::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
.content-container{
    width: 100%;
    margin-top: 20px;
    color: var(--lightBlack);
}
.content-heading{
    font-size: var(--h1);
    font-weight: 700;
    color: var(--lightBlack);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content-option{
    border-radius: 50%;
    padding: 18px 18px;
    position: relative;
    cursor: pointer;
}
.content-option:hover{
    background-color: var(--accent2);
}
.content-option > svg{
    position: absolute;
    top: 25%;
    left: 25%;
}
.content-subheading{
    font-size: var(--h6);
    color: var(--accent3);
    margin: 20px 0px;
}
.content-para{
    font-size: var(--para);
    color: var(--lightBlack);
    line-height: 1.3;
    margin: 10px 0px;
}
.content-focus{
    margin: 30px 0px;
    padding: 15px 20px 15px 0px;
    border-radius: 4px;
    border-left: 4px solid var(--linkBlue);
    background-color: var(--accent2);
    display: flex;
}
.content-focus-icon{
    margin: 0px 10px;
}
.content-para-heading{
    font-weight: 700;
}
.content-heading6{
    font-size: var(--h5);
    color: var(--lightBlack);
    font-weight: 700;
    margin: 20px 0px;
}
.content-image{
    width: 100%;
    height: fit-content;
    position: relative;
    margin: 40px 0px;
}
.content-image > img{
    width: 100%;
    height: 450px;
}
.content-image > p{
    font-size: 12px;
    color: var(--accent3);
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
}
.content-list{
    padding: 20px;
}
.content-li{
    font-weight: 700;
}
.content-para-indent{
    margin-left: 20px;
}

@media (max-width: 800px) and (min-width: 300px) {
    .content-section{
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
}