.grp-heading{
    font-size: var(--small-para);
    padding-left: 15px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-top: 1px solid #ccc;
    padding-top: 10px;
}
.grp-heading > p{
    color: var(--accent3);
    font-weight: 700;
}
.grp-menu-icon{
    visibility: hidden;
    margin-right: 10px;
    cursor: pointer;
}
.grp-heading:hover > .grp-menu-icon{
    visibility: visible;
}

.grp-menu{
    background-color: white;
    padding: 10px;
    position: absolute;
    left: 85%;
    top: 100%;
    border: 1px solid var(--accent4);
    border-radius: 5px;
    width: 220px;
    z-index: 9;
    display: none;
}
.grp-menu > p{
    padding: 6px 10px;
    margin: 5px 0px;
    font-size: var(--medium-para);
}
.grp-menu > p:hover{
    cursor: pointer;
    background-color: var(--accent2);
    border-radius: 4px;
}
.grp-menu > p > span{
    margin-right: 10px;
    position: relative;
    top: 4px;
}
.grp-menu-active{
    display: block;
}
.page-line{
    border: 0.5px solid #ccc;
}
