@import url(../../App.css);
.header-section{
    width: 100%;
    border-bottom: 1px solid #ccc;
    background-color: white;
    position: fixed;
    z-index: 9;
}
.header-container{
    width: 90%;
    margin: auto;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-left{
    display: flex;
    align-items: center;
}
.header-hamburger{
    position: relative;
    top: 3px;
    margin-right: 10px;
    cursor: pointer;
    display: none;
}
.header-left > p{
    font-weight: 700;
    font-size: 30px;
    display: flex;
    align-items: center;
}
.header-left > p >img{
    margin-right: .4rem;
}
.header-search{
    background-color: var(--searchGrey);
    border-radius: 8px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.header-search-hide{
    display: none;
}
.header-search > input{
    padding: 12px;
    width: 180px;
    background: transparent;
    border: none;
    outline: none;
}
.header-search-sc{
    background-color: white;
    padding: 4px;
    color: var(--linkBlue);
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
}
.header-right{
    display: flex;
    align-items: center;
}
.header-signin > a{
    padding: 10px 25px;
    border-radius: 25px;
    font-weight: 600;
    background-color: #0980db;
    color: white;
    text-decoration: none;

}
.header-signin-hide{
    display: none;
}
.header-signin-link{
    color: white;
    text-decoration: none;
    background-color: var(--linkBlue);
}

@media (max-width: 800px) and (min-width: 300px) {
    .header-hamburger{
        display: block;
    }
    .hide-hamburger{
        display: none;
    }
}