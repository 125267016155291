@import url(../../App.css);
.ip-section{
    width: 100%;
    height: 100vh;
    background: var(--bg-color1);
    padding: 15px 0px;
}
.ip-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.header-search{
    background-color: var(--searchBlue);
    border-radius: 8px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 0px 15px;
}
.header-search > input{
    padding: 12px;
    width: 180px;
    background: transparent;
    border: none;
    outline: none;
    color: var(--white);
}
.header-search-sc{
    background-color: var(--linkBlue);
    padding: 4px;
    color: var(--white);
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
}
.line{
    border-bottom: 1px solid var(--searchBlue);
    height: 20px;
}
.ip-organize{
    padding: 15px ;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ip-organize-left{
    display: flex;
    align-items: center;
}
.ip-organize-img{
    background-color: var(--white);
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-right: 8px;
}
.ip-organize-name{
    color: var(--white);
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.ip-organize-name > p{
    outline: none;
}
.ip-organize-dd{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.ip-organize:hover{
    background: var(--searchBlue);
    cursor: pointer;
}
.ip-button{
    display: flex;
    align-items: center;justify-content: space-between;
    padding: 15px;
    position: relative;
}
.ip-button-left, .ip-button-right{
    cursor: pointer;
}
.ip-button-left, .ip-button-right{
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.ip-button-left:hover, .ip-button-right:hover{
    background-color: var(--searchBlue);
}
.ip-button-mid{
    height: 40px;
    width: 40px;
    background-color: var(--linkBlue);
    border-radius: 50%;
    font-size: var(--h5);
    color: white;
    text-align: center;
    cursor: pointer;
}
.ip-button-mid > img{
    position: relative;
    top: 8px;
    transition: transform 1s ease-in-out;
}
.ip-button-mid:hover > img{
    transform: rotate(360deg);
}

.setting-menu{
    background-color: white;
    padding: 10px;
    position: absolute;
    left: 80%;
    bottom: 80%;
    border: 1px solid var(--accent4);
    border-radius: 5px;
    width: 220px;
    display: none;
}
.setting-menu > p{
    padding: 6px 10px;
    margin: 5px 0px;
    font-size: var(--medium-para);
}
.setting-menu > p:hover{
    cursor: pointer;
    background-color: var(--accent2);
    border-radius: 4px;
}
.setting-menu > p > span{
    margin-right: 10px;
    position: relative;
    top: 4px;
}
.setting-menu-active{
    display: block;
}

.noti-panel{
    background-color: white;
    position: absolute;
    left: 10%;
    bottom: 80%;
    border: 1px solid var(--accent4);
    border-radius: 5px;
    width: 420px;
    height: 320px;
    display: none;
}
.noti-header{
    padding: 10px 15px;
    border-bottom: 1px solid var(--accent4);
    color: var(--lightBlack);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mark-read{
    padding: 5px 8px;
}
.mark-read:hover{
    background-color: var(--accent2);
    cursor: pointer;
    border-radius: 4px;
}
.noti-panel-active{
    display: block;
}

.overlay-space-container{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.overlay-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.space-input{
    width: 100%;
    padding: 8px;
    margin-top: 10px;
    font-size: var(--para);
    outline: none;
    border: 1px solid var(--accent3);
    border-radius: 4px;
}
.space-input:focus{
    border: 1px solid var(--lightBlack);
}
.space-text{
    font-size: var(--medium-para);
    color: var(--accent3);
    font-weight: 700;
}
.space-text:has(~ .space-input:focus){
    color: var(--lightBlack);
}
.close-btn{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.close-btn-icon{
    cursor: pointer;
}
.save-space{
    border: 1px solid var(--lightBlack);
    padding:  5px 10px;
    border-radius: 4px;
    color: var(--lightBlack);
    cursor: pointer;
    width: 30%;
    text-align: center;
    font-weight: 600;
    background: white;
    transition: background 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.save-space:hover{
    background: var(--linkBlue);
    border: 1px solid var(--linkBlue);
    color: white;
}
.space-btn-box{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.space-public{
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: var(--accent3);
}
.custom-checkbox{
    appearance: none;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid var(--accent3);
    height: 18px;
    width: 18px;
    overflow: hidden;
    display: grid;
    place-content: center;
}
.custom-checkbox::before{
    content: "";
    height: 14px;
    width: 14px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
}
.custom-checkbox:checked::before {
    content: "\2713";
    font-size: var(--para);
    color: var(--linkBlue);
    transform: scale(1);
    text-align: center;
    display: grid;
    place-content: center;
}
.shared-space{
    margin: .5rem 0rem;
}
.shared-space-heading{
    margin-bottom: .5rem;
    font-weight: 600;
    font-size: .9rem;
    padding: 0 .5rem;
    color: var(--white);
}
.ip-section-mob{
    display: none;
}

/* Responsive CSS */
@media (max-width: 800px) and (min-width: 300px) {
    .ip-section{
        display: none;
        position: absolute;
        top: 50px;
        width: 70%;
        z-index: 9;
    }
    .ip-section-mob{
        display: block;
        width: 100%;
        background: var(--bg-color1);
        padding: 15px 15px;
    }
    .ip-container-mob{
        display: flex;
        height: 100%;
    }
    .ip-organize-name{
        margin-left: 20px;
        margin-top: 1px;
        font-weight: 700;
        color: var(--accent1);
    }
    .ip-sidebar-icon{
        cursor: pointer;
    }
}