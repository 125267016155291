@import url(../../App.css);
.sbl-container{
    padding-left: 20px;
    margin: 10px 0px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
}
.sbl-container > a{
    text-decoration: none;
    font-size: var(--medium-para);
    color: var(--sideGrey);
    text-overflow: ellipsis;
}
.sbl-container:hover a{
    color: var(--linkBlue);
}
.active{
    border-left: 2px solid var(--linkBlue);
    font-weight: 700;
}
.active > a{
    color: var(--linkBlue);
}