a{
    text-decoration: none;
}
.buildfast-pricing{
    width: 100%;
    height: auto;
}
.buildfast-pricing-panel{
    width: 100%;
    margin: 3rem 0rem;
    display: flex;
    justify-content: center;
}
.buildfast-pricing-container{
    width: 95%;
}
.buildfast-pricing-heading{
    display: flex;
    justify-content: center;
    align-items: center;
}
.buildfast-pricing-heading > p{
    font-size: 2.8rem;
    font-weight: 600;
}
.buildfast-pricing-card-container{
    width: 100%;
    margin: 3rem 0rem;
    height: auto;
}
.buildfast-pricing-all-card{
    width: 100%;
    display: flex;
    justify-content: center;
    height: auto;
}
.buildfast-pricing-card{
    width: 100%;
    height: auto;
    margin: 0.625rem 1.25rem;
    padding: 2.25rem;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: hidden;
}
.buildfast-pricing-card:hover{
    box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.2);
}
.buildfast-card-top{
    display: flex;
    justify-content: space-between;
    height: 70px;
}
.buildfast-feature-header{
    width: 100%;
}
.buildfast-feature-header-element{
    width: 130px;
    border: 2px solid #EBEBEB;
    background-color: #F7F7F7;
    text-align: center;
    border-radius: 25px;
    font-size: 1rem;
    font-weight: 500;
    color: #000000;
    padding: 3px 3px;
}
.header-element1{
    width: 70px;
}
.header-element2{
    width: 80px;
}
.buildfast-card-top-img{
    position: relative;
    bottom: 2.25rem;
    left: 3rem;
}
.buildfast-card-top-img > img{
    width: 150px;
    height: 150px;
}
.buildfast-card-middle{
    width: 100%;
}
#middle-price{
    color: #3F54BD;
}
.card3 .buildfast-card-middle > img{
    height: 60px;
    width: 60px;
    margin: 0.6rem 0rem;
}
.buildfast-card-middle > p:nth-child(1){
    font-size: 3.5rem;
    font-weight: 500;
}
.buildfast-card-middle > p:nth-child(2){
    font-size: 0.9rem;
    margin: 0.5rem 0rem;
    color: grey;
}
.buildfast-card-middle > p:nth-child(2){
    margin: 0.5rem 0rem;
}
.buildfast-pricing-btn{
    background-color: rgba(0, 0, 0, 0.809);
    padding: 0.8rem 1rem;
    margin: 2rem 0rem;
    color: white;
    text-align: center;
    border-radius: 2.5rem;
}
.card1 .buildfast-pricing-btn:hover{
    background-color: black;
}
.card2 .buildfast-pricing-btn{
    background-color: #3f54bdd7;
}
.card2 .buildfast-pricing-btn:hover{
    background-color: #3F54BD;
}
.card3 .buildfast-pricing-btn{
    background-color: #f2f3f5e9;
    color: #000;
}
.card3 .buildfast-pricing-btn:hover{
    background-color: #dddddd;
    color: #000;
}
.buildfast-card-bottom{
    width: 100%;
}
.buildfast-card-bottom-heading>p{
    font-weight: 600;
}
.buildfast-card-info-row{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 0rem 0.6rem;
    border-bottom: 1px solid #cccccc;
}
.buildfast-card-info-row > p{
    padding-left: 0.8rem;
    font-size: 0.8rem;
}
.buildfast-card-info-row > img{
    width: 20px;
    height: 20px;
}
.buildfast-bottom-text{
    font-size: 0.7rem;
}

/* Responsive CSS */
@media screen and (min-width: 900px) and (max-width: 1030px) {
    html{
        font-size: 14px;
    }
    .buildfast-pricing-card{
        margin: 0.625rem;
    }
    .buildfast-card-top-img{
        position: relative;
        bottom: 2.25rem;
        left: 3.2rem;
    }

}
@media screen and (min-width: 500px) and (max-width: 900px) {
    body{
        overflow-x: hidden;
    }
    html{
        font-size: 14px;
    }
    .buildfast-pricing-all-card{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .buildfast-pricing-card{
        width: 50%;
    }
}
@media screen and (min-width: 310px) and (max-width: 500px) {
    body{
        overflow-x: hidden;
    }
    html{
        font-size: 14px;
    }
    .buildfast-pricing-heading > p{
        font-size: 2rem;
    }
    .buildfast-pricing-all-card{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .buildfast-pricing-card{
        width: 100%;
    }
}