.leftbar-section{
    width: 20%;
    min-height: 100vh;
    padding: 10px 10px 10px 25px;
    border-right: 1px solid #ccc;
    padding-top: 100px;
    height: 100vh;
    overflow-y: scroll;
    z-index: 6;
}
.leftbar-container{
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
@media (max-width: 800px) and (min-width: 300px) {
    .leftbar-section{
        width: 70%;
        left: -100%;
        position: absolute;
        background-color: white;
        transition: all 1s ease-in-out;
    }
    .leftbar-active{
        left: 0px;
    }
}
  