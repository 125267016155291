.ce-block:first-child > .ce-block__content > .ce-header{
    font-size: 2.3rem;
}
.image-tool__caption[contenteditable="false"]{
    font-size: .8rem;
    border: none;
    text-align: center;
}
.cdx-input[contenteditable="false"]{
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    padding: 0rem 0rem 1rem;
    color: var(--accent3);
}
.tc-wrap--readonly {
    border-left: 1px solid #e8e8eb;
}