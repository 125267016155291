@import url(../../App.css);
.saved-overlay-container{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.saved-overlay-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.saved-overlay-text{
    font-size: var(--para);
    color: var(--lightBlack);
    font-weight: 700;
}
.ssaved-overlay-text:has(~ .space-input:focus){
    color: var(--lightBlack);
}
.close-btn{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.close-btn-icon{
    cursor: pointer;
}
.saved-overlay-btn-box{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
/* .save-space, .discard-space{
    border: 1px solid var(--lightBlack);
    margin-top: 20px;
    margin-left: 20px;
    padding:  10px 15px;
    border-radius: 4px;
    color: var(--lightBlack);
    cursor: pointer;
    width: 30%;
    text-align: center;
    font-weight: 600;
    background: white;
    transition: background 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.save-space:hover{
    background: var(--linkBlue);
    border: 1px solid var(--linkBlue);
    color: white;
}
.discard-space:hover{
    background: var(--deleteRed);
    border: 1px solid var(--deleteRed);
    color: white;
} */