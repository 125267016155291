@import url(../../App.css);
.ssb-container{
    width: 25%;
    background-color: var(--accent2);
    border-right: 1px solid #ccc;
    height: calc(100vh - 68px);
}
.ssb-container-top, .ssb-container-bottom{
    padding: 10px 0 10px 15px;
}
.ssb-container-bottom-hide{
    display: none;
}
.page-heading{
    position: relative;
    padding: 10px 0 10px 15px;
    cursor: pointer;
    border-left: 1px solid var(--accent2);
    border-top: 1px solid var(--accent2);
    border-bottom: 1px solid var(--accent2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--medium-para);
}
.page-heading > div:first-child{
    visibility: hidden;
    margin-right: 10px;
}
.page-heading:hover > div:first-child{
    visibility: visible;
}
.page-heading:hover{
    border-left: 1px solid var(--accent3);
    border-top: 1px solid var(--accent3);
    border-bottom: 1px solid var(--accent3);
    background-color: var(--white);
}
.page-heading-active{
    border-left: 1px solid var(--accent3);
    border-top: 1px solid var(--accent3);
    border-bottom: 1px solid var(--accent3);
    background-color: var(--white);
}
.page-heading-active > div:first-child{
    visibility: visible;
}
.page-heading-active > p{
    color: var(--linkBlue);
}
.page-line{
    border: 0.5px solid #ccc;
}
.add-page{
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: var(--accent5);
    cursor: pointer;
}
.add-page > svg{
    margin-right: 10px;
}
.add-page:hover{
    background-color: #eceff1;
}
.addpage-menu{
    background-color: var(--white);
    padding: 10px;
    margin-right: 10px;
    border: 1px solid var(--accent4);
    border-radius: 6px;
    display: none;
}
.addpage-menu-active{
    display: block;
}
.addpage-menu >p{
    padding: 6px 10px;
    margin: 5px 0px;
    font-size: var(--medium-para);
}
.addpage-menu > p:hover{
    cursor: pointer;
    background-color: var(--accent2);
    border-radius: 4px;
}
.addpage-menu > p > span{
    margin-right: 10px;
    position: relative;
    top: 4px;
}

.page-content{
    position: relative;
}
.page-menu{
    background-color: white;
    padding: 10px;
    position: absolute;
    left: 90%;
    top: 85%;
    border: 1px solid var(--accent4);
    border-radius: 5px;
    width: 220px;
    z-index: 9;
    display: none;
}
.page-menu > p{
    padding: 6px 10px;
    margin: 5px 0px;
    font-size: var(--medium-para);
}
.page-menu > p:hover{
    cursor: pointer;
    background-color: var(--accent2);
    border-radius: 4px;
}
.page-menu > p > span{
    margin-right: 10px;
    position: relative;
    top: 4px;
}
.page-menu-active{
    display: block;
}

/* Responsive CSS */
@media (max-width: 800px) and (min-width: 300px){
    .ssb-container{
        position: absolute;
        width: 55%;
        left: -100%;
        z-index: 8;
        transition: all 0.5s ease;
    }
    .open-ssb-container{
        left: 0%;
    }
}