@import url(../../App.css);
.ia-container{
    display: none;
}
.ia-container-show{
    display: flex;
    width: 75%;
    padding: 30px 60px;
    justify-content: space-between;
    flex-direction: column;
    max-height: calc(100vh - 68px);
}
.movepage-btn{
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.movepage-left, .movepage-right{
    border: 1px solid var(--accent4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-radius: 4px;
    width: 48%;
    cursor: pointer;
}
.movepage-left-disabled, .movepage-right-disabled{
    pointer-events: none;
    opacity: 0.6;
    border: 1px solid var(--accent4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-radius: 4px;
    width: 48%;
}
.btn-text-left > p{
    text-align: right;
    line-height: 1.5;
}
.btn-text-left > p:nth-child(1){
    font-size: var(--small-para);
    color: var(--accent3);
}
.btn-text-left > p:nth-child(2){
    font-weight: 600;
}
.btn-text-right > p{
    line-height: 1.5;
}
.btn-text-right > p:nth-child(1){
    font-size: var(--small-para);
    color: var(--accent3);
}
.btn-text-right > p:nth-child(2){
    font-weight: 600;
}
.movepage-left:hover, .movepage-right:hover{
    transition-duration: 0.15s;
    touch-action: manipulation;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 12px 13px;
    transform: translateY(-2px);
}
.text-editor-container{
    overflow: scroll;
}
.no-editor-content{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    background-color: var(--accent2);
    max-height: calc(100vh - 68px);
}
.no-editor-content-hide{
    display: none;
}
.editor-msg{
    text-align: center;
}
.editor-msg > p{
    font-size: var(--h5);
    margin-top: 20px;
    color: var(--accent3);
}
.editor-msg > p >span{
    font-size: var(--medium-para);
}
/* Responsive CSS */
@media (max-width: 800px) and (min-width: 300px){
    .ia-container{
        width: 100%;
        padding: 10px 30px;
        display: none;
        justify-content: space-between;
        flex-direction: column;
        max-height: calc(100vh - 68px);
    }
    .no-editor-content{
        width: 100%;
        min-height: 200px;
        height: calc(100vh - 68px);
    }
    .ia-container-show{
        width: 100%;
        padding: 30px;
    }
    .movepage-btn{
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .movepage-left, .movepage-right{
        width: 100%;
        margin-top: 10px;
    }
}