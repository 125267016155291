@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Inria+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
:root{
    --black: #000;
    --white: #fff;
    --lightBlack : #3B454E;
    --deleteRed: #c70000;
    --linkBlue: #0073cf;
    --sideGrey: #5B6975;
    --searchGrey: #EBECED;
    --searchBlue: #283143;
    --accent1 : #eceff1;
    --accent2 : #F2F6FB;
    --accent3 : #8898A7;
    --accent4: #dddddd;
    --accent5: #5B6975;
    --bg-color1: #333D55;



    --h1: 40px;
    --h2: 36px;
    --h3: 32px;
    --h4: 28px;
    --h5: 24px;
    --h6: 20px;
    --para: 16px;
    --medium-para: 15px;
    --small-para: 13px;
    
}
a{
    text-decoration: none;
}

/* CSS for User Input Page */
.ui-section{
    display: flex;
}
.ui-left{
    width: 18%;
}
.ui-right{
    width: 82%;
}
.ui-right-bottom{
    display: flex;
    justify-content: sb;
}

/* CSS for Login Pages */
.both{
    padding-top: 70px;
    width: 100%;
    height: 100vh;
    display: flex;
    font-family: 'Inria Sans';;
}
.left-login-section{
    /* background: #000; */
    background: url('./assets/images/loginImg2.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 60%;
}
.right{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
}
.right-content{
    width: 29.375rem;
    margin: auto;
    text-align: center;
}
.right-header{
    font-size: 3rem;
    font-weight: 700;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 3rem;
    font-family: 'Inria Sans';
}
.right-subheader{
    margin-bottom: 6rem;
    color: #868686;
}
.right-heading{
    font-family: 'Inria Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 2.1rem;
    line-height: 2.6875rem;
}
.right-para{
    width: 80%;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: 'Inria Sans';
    color: #868686;
    margin: 1.5625rem auto 1.875rem;
}
.right-google, .right-facebook{
    display: flex;
    justify-content: center;
    background: #FFFFFF;
}
.right-google > p, .right-facebook > p{
    margin-left: 0.3125rem;
    color: black;
}
.right-google > img, .right-facebook > img{
    margin-right: 0.3125rem;
    height: 1.5em;
    width: 1.5em;
}

.left-content{
    position: relative;
    top: 3.9375rem;
    width: 35.375rem;
    margin: auto;

}
.left-heading{
    font-family: 'Inria Serif';
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.625rem;
    margin-left: 3.0625rem;
    color: #FFFFFF;
}
.left-para{
    font-family: 'Inria Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.8125rem;
    margin-left: 3.0625rem;
    color: #FFFFFF;
    margin-top: 1.25rem;
}
.pic{
    margin-top: 4.6875rem;
}
.pic >img{
    width: 100%;
    /* height: 35.375rem; */
}

@media (max-width: 1030px) and (min-width: 800px) {
    .ui-section{
        display: flex;
    }
    .ui-left{
        width: 25%;
    }
    .ui-right{
        width: 75%;
    }
    .ui-right-bottom{
        display: flex;
        justify-content: sb;
    }
}
@media (max-width: 800px) and (min-width: 300px) {
    .ui-section{
        display: block;
    }
    .ui-left{
        width: 100%;
    }
    .ui-right{
        width: 100%;
    }
    .ui-right-bottom{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .left-login-section{
        display: none;
    }
    .right{
        background-position: center;
        width: 100%;
        padding: 0px 25px;
    }
    .right-subheader{
        display: none;
    }
    .right-header{
        display: none;
    }
}