@import url(../../App.css);
.overlay-container{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.overlay-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.space-input{
    width: 100%;
    padding: 8px;
    margin-top: 10px;
    font-size: var(--para);
    outline: none;
    border: 1px solid var(--accent3);
    border-radius: 4px;
}
.space-input:focus{
    border: 1px solid var(--lightBlack);
}
.space-text{
    font-size: var(--medium-para);
    color: var(--accent3);
    font-weight: 700;
}
.space-text:has(~ .space-input:focus){
    color: var(--lightBlack);
}
.close-btn{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.close-btn-icon{
    cursor: pointer;
}
.rename-space{
    border: 1px solid var(--lightBlack);
    margin-top: 30px;
    padding:  5px 10px;
    border-radius: 4px;
    color: var(--lightBlack);
    cursor: pointer;
    width: 30%;
    text-align: center;
    font-weight: 600;
    background: white;
    transition: background 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.rename-space:hover{
    background: var(--linkBlue);
    border: 1px solid var(--linkBlue);
    color: white;
}
.new-space-btn-box{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.space-public{
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: var(--accent3);
}